<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const router = useRouter();
const loginGuard = useLoginGuard();

const { tournamentData, activeStatus, isSubscribed, loadingSubscribtions, coinsSum, entriesSum, handleSubscribe } =
	useRace();

const tournamentExist = computed(() => tournamentData.value?.isActiveStatus);

const textBtn = computed(() => {
	if (!tournamentExist.value) {
		return t("All Games");
	}
	if (!activeStatus.value) {
		return t("More Info");
	}
	return isSubscribed.value ? t("Play now") : t("Join Tournament");
});

const handleSubscribeRace = () => {
	loginGuard({
		success: () => {
			if (!tournamentExist.value) {
				router.push("/issues/all-games/");
				return;
			}
			if (!activeStatus.value) {
				navigateTo("/tournaments/");
				return;
			}
			handleSubscribe({
				id: tournamentData.value?.data?.id || 0,
				isSub: isSubscribed.value
			});
			open("LazyOModalRacesGames", { isRaceMode: true });
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg class="image" src="/nuxt-img/tournaments/banner-tour.webp" alt="banner-tour" quality="100" />
		<div class="content">
			<AText variant="tempe" :modifiers="['semibold']">
				<span>{{ t("Tournament Announcement") }}</span>
			</AText>
			<AText v-if="tournamentExist" variant="taipei" :modifiers="['semibold']" class="label">
				<span>
					{{ t("The Prize Pool is") }}
					<span v-if="coinsSum">{{ numberFormat(coinsSum) }} GC</span>
					<span v-if="coinsSum && entriesSum">
						{{ " & " }}
					</span>
					<span v-if="entriesSum">{{ numberFormat(entriesSum) }} free SC</span>
				</span>
			</AText>
			<AText v-else variant="taipei" :modifiers="['semibold']" class="label">
				{{ t("Coming Soon") }}
			</AText>
			<AAnimationCustomByScroll animationClass="blink" transitionDelay="3s" class="btn-wrapper">
				<AButton variant="primary" class="btn" size="lg" @click="handleSubscribeRace">
					<AAnimationSpin v-if="loadingSubscribtions">
						<NuxtIcon name="24/loader" filled />
					</AAnimationSpin>
					<AText v-else variant="toledo" :modifiers="['medium']">
						{{ textBtn }}
					</AText>
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
	height: 50%;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	padding: 24px;
	position: relative;
	overflow: hidden;
}

.content {
	max-width: 320px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: relative;
	z-index: 1;
}

.label {
	margin-top: 4px;
}

.image {
	height: 100%;
	min-width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.btn-wrapper {
	margin-top: auto;
}

.btn {
	width: 165px;
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}
</style>
